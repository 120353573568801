import ShareButton from 'Components/ShareButton'
import animate from 'Components/ui/animate'
import { findContrastedTextColor } from 'Components/utils/colors'
import { IframeOptionsContext } from 'Components/utils/IframeOptionsProvider'
import Meta from 'Components/utils/Meta'
import { AnimatePresence, motion, useSpring } from 'framer-motion'
import { utils } from 'publicodes'
import { default as React, useContext, useEffect, useState } from 'react'
import emoji from 'react-easy-emoji'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { answeredQuestionsSelector } from 'Selectors/simulationSelectors'
import { last } from 'Source/utils'
import tinygradient from 'tinygradient'
import SlidesLayout from '../../../components/SlidesLayout'
import { useSearchParams } from '../../../components/utils/useSearchParams'
import Chart from '../chart'
import DefaultFootprint from '../DefaultFootprint'
import HorizontalSwipe from '../HorizontalSwipe'
import BallonGES from './ballonGES.svg'
import { ActionButton, IntegratorActionButton } from './Buttons'
import IframeDataShareModal from './IframeDataShareModal'
import Petrogaz from './Petrogaz'
const { encodeRuleName } = utils
import { HeaderFFF } from '../App'
import { FFFButton } from './Buttons'
import {
	deletePreviousSimulation,
	resetActionChoices,
	resetSimulation,
	resetTutorials,
	resetStoredTrajets,
	goToQuestion
} from 'Actions/actions'

const gradient = tinygradient([
		'#78e08f',
		'#e1d738',
		'#f6b93b',
		'#b71540',
		'#000000',
	]),
	colors = gradient.rgb(20)

const getBackgroundColor = (score) =>
	colors[
		Math.round((score < 2000 ? 0 : score > 20000 ? 19000 : score - 2000) / 1000)
	]

// details=a2.6t2.1s1.3l1.0b0.8f0.2n0.1
const rehydrateDetails = (encodedDetails) =>
	encodedDetails &&
	encodedDetails
		.match(/[a-z][0-9]+\.[0-9][0-9]/g)
		.map(([category, ...rest]) => [category, 1000 * +rest.join('')])
		// Here we convert categories with an old name to the new one
		// 'biens divers' was renamed to 'divers'
		.map(([category, ...rest]) =>
			category === 'b' ? ['d', ...rest] : [category, ...rest]
		)

const sumFromDetails = (details) =>
	details.reduce((memo, [name, value]) => memo + value, 0)

export default ({}) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const encodedDetails = searchParams.get('details')
	const slideName = searchParams.get('diapo') || 'bilan'

	const rehydratedDetails = rehydrateDetails(encodedDetails)

	const score = sumFromDetails(rehydratedDetails)
	const headlessMode =
		!window || window.navigator.userAgent.includes('HeadlessChrome')

	const dispatch = useDispatch(),
		answeredQuestions = useSelector(answeredQuestionsSelector)

	const slideProps = {
		score,
		details: Object.fromEntries(rehydratedDetails),
		headlessMode,
	}

	const Component = {
		bilan: Budget,
		pétrogaz: Petrogaz,
	}[slideName]

	const next = () => {
			const nextSlide = slideName === 'bilan' ? 'pétrogaz' : 'bilan'
			setSearchParams({ diapo: nextSlide, details: encodedDetails })
		},
		previous = next

	return (
		<div>
			<IframeDataShareModal data={rehydratedDetails} />
			<Budget {...slideProps} />
		</div>
	)
}

const CarbonBudget = (props) => {
	const { integerValue, score, decimalValue, integratorActionText } = props;
	const impactPart = Math.round(score / 2000 * 100);
	return (
		<div
			css={`
				position: relative;
				width:100%;
				padding: 32px 32px 64px;
				background-color: var(--gold);
				* {
					color: var(--purple);
					font-family: 'Poppins', sans-serif;
					margin: 0;
				}
				p:first-child {
					font-size: 50px;
					line-height: 60px;
					font-weight: bold;
					margin-bottom: 0;
				}
				p:last-child {
					font-size: 18px;
					font-weight: 300;
					margin-bottom: 0;
				}
				@media (max-width: 800px) {
					p:first-child {
						font-size: 40px;
						font-weight: bold;
						margin-bottom: 4px;
					}
					p:last-child, #co2 {
						font-size: 20px;
						font-weight: 300;
					}
				}
				p.indicator {
					font-size: 12px;
					line-height: 18px;
					letter-spacing: 2.4px;
					margin: 0;
					span {
						font-weight: bold;
					}
				}
				a {
					position: absolute;
					bottom: 0;
					transform: translate(-50%, 50%);
				}
			`}
		>
			<p>{integerValue},<span>{decimalValue} TONNES</span> <span id="co2"></span></p>
			<p>(CO2eq) générée(s) par votre pratique du football<br/></p>
			<p className='indicator'>Budget max global : <span>2 tonnes</span></p>
			<p className='indicator'>Part de ma pratique : <span>{impactPart}%</span></p>
			{!integratorActionText && (
				<ActionButton text="Passer à l'action" score={score} />
			)}
		</div>
	)
}


const Budget = ({ score, details, headlessMode }) => {
	//	Configuration is try and test, feeling, really
	const valueSpring = useSpring(0, {
		mass: 10,
		stiffness: 50,
		damping: 60,
	})

	const [value, setValue] = useState(0)

	useEffect(() => {
		const unsubscribe = valueSpring.onChange((v) => {
			setValue(v)
		})

		headlessMode ? setValue(score) : valueSpring.set(score)

		return () => unsubscribe()
	})

	const backgroundColor = getBackgroundColor(value).toHexString(),
		backgroundColor2 = getBackgroundColor(value + 2000).toHexString(),
		textColor = findContrastedTextColor(backgroundColor, true),
		roundedValue = (value / 1000).toLocaleString('fr-FR', {
			maximumSignificantDigits: 2,
			minimumSignificantDigits: 2,
		}),
		integerValue = roundedValue.split(',')[0],
		decimalValue = roundedValue.split(',')[1],
		shareImage = generateImageLink(window.location)

	const { integratorYoutubeVideo, integratorActionText, integratorActionUrl } =
		useContext(IframeOptionsContext)

	const dispatch = useDispatch(),
		answeredQuestions = useSelector(answeredQuestionsSelector)

	return (
		<div>
			<Meta
				title="Mon empreinte climat"
				description={`Mon empreinte climat est de ${roundedValue} tonnes de CO2e. Mesure la tienne !`}
				image={shareImage}
				url={window.location}
			/>
			<div
				className=""
				id="fin"
				css={`
					color: ${textColor};
					height: 100vh;
					margin: 0 auto;
					border-radius: 0.6rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					text-align: center;
					font-size: 110%;
				`}
			>
				<div>
					<div css={`
							width: 100%;
							height: 100px;
						`}>
						<HeaderFFF />
						</div>
						<CarbonBudget 
							integerValue={integerValue}
							score={score}
							decimalValue={decimalValue}
							integratorActionText={integratorActionText}
						/>
						<div css={`
							background-color: white;
							padding: 16px;
						`}>
						<Chart
							noAnimation
							details={details}
							links
							color={textColor}
							noText
							noCompletion
							valueColor={textColor}
						/>
					</div>

					
				</div>

				<div css={`
					display: flex;
					align-items: center;
					justify-content: center;
				`}>
					<FFFButton 
						type='Link'
						to="/simulateur/bilan"
						colorType="fff_blue"
						fontSize="14px"
						fontWeight="bold"
						onClick={() => {
							dispatch(resetSimulation())
		dispatch(resetActionChoices())
		dispatch(deletePreviousSimulation())
		dispatch(resetTutorials())
		dispatch(resetStoredTrajets())
						}}
						text="Nouvelle Simulation"
					/>

				</div>
				
				<div css={`
					display: grid;
					align-items: center;
					grid-template-columns: repeat(3, 25%);
					gap: 12.5%;
					padding: 16px;
				`}>
					<FFFButton 
						type='Link'
						to="/simulateur/bilan"
						colorType="fff_white"
						fontSize="10px"
						fontWeight="normal"
						onClick={() => {
							dispatch(goToQuestion(last(answeredQuestions)))
						}}
						text="Revenir à la simulation"
					/>
					{integratorActionText && <ActionButton text="Réduire mon empreinte" />}
					<DocumentationEndButton ruleName={'bilan'} color={textColor} />
					<ShareButton
						text="Voilà mon empreinte 🌍️climat. Mesure la tienne !"
						url={window.location}
						title={'Nos Gestes Climat'}
						color={textColor}
						label="Partager mes résultats"
					/>
				</div>
				

				{integratorActionText && integratorActionUrl && (
					<IntegratorActionButton />
				)}

				{/* {integratorYoutubeVideo && (
					<div
						class="videoWrapper"
						css={`
							iframe {
								width: 100%;
							}
						`}
					>
						<iframe
							width="560"
							height="315"
							src={integratorYoutubeVideo}
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				)} */}

				
			</div>
		</div>
	)
}




export const generateImageLink = (location) =>
	'https://aejkrqosjq.cloudimg.io/v7/' +
	location.origin +
	'/.netlify/functions/ending-screenshot?pageToScreenshot=' +
	encodeURIComponent(location)

export const DocumentationEndButton = ({ ruleName, color }) => (
	<FFFButton
		type='Link'
		to={'/documentation/' + encodeRuleName(ruleName)}
		text="Comprendre le calcul"
		colorType="fff_white"
		fontSize="10px"
		fontWeight="normal"
	/>
)
