import Route404 from 'Components/Route404'
import { sessionBarMargin } from 'Components/SessionBar'
import 'Components/ui/index.css'
import News from 'Pages/News'
import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import Provider from '../../Provider'
import {
	persistSimulation,
	retrievePersistedSimulation,
} from '../../storage/persistSimulation'
import Tracker, { devTracker } from '../../Tracker'
import About from './About'
import Actions from './Actions'
import Contribution from './Contribution'
import Diffuser from './Diffuser'
import Fin from './fin'
import Landing from './Landing'
import { LogoFFF } from './Logo'
import Navigation from './Navigation'
import Documentation from './pages/Documentation'
import Personas from './Personas.tsx'
import Profil from './Profil.tsx'
import Tutorial from './Tutorial.tsx'
import Simulateur from './Simulateur'
import sitePaths from './sitePaths'
import GroupSwitch from './conference/GroupSwitch'

const ConferenceLazy = React.lazy(() => import('./conference/Conference'))
const StatsLazy = React.lazy(() => import('./pages/Stats'))

const SurveyLazy = React.lazy(() => import('./conference/Survey'))

const CGULazy = React.lazy(() => import('./CGU.tsx'))
const PrivacyLazy = React.lazy(() => import('./Privacy.js'))

const GuideGroupeLazy = React.lazy(() => import('./pages/GuideGroupe'))

const DocumentationContexteLazy = React.lazy(
	() => import('./pages/DocumentationContexte')
)

let tracker = devTracker
if (NODE_ENV === 'production') {
	tracker = new Tracker()
}

export default function Root({}) {
	const { language } = useTranslation().i18n
	const paths = sitePaths()

	const urlParams = new URLSearchParams(window.location.search)
	/* This enables loading the rules of a branch,
	 * to showcase the app as it would be once this branch of -data  has been merged*/
	const branch = urlParams.get('branch')
	const pullRequestNumber = urlParams.get('PR')

	const iframeShareData = new URLSearchParams(
		document?.location.search.substring(1)
	).get('shareData')

	const persistedSimulation = retrievePersistedSimulation()
	return (
		<Provider
			tracker={tracker}
			sitePaths={paths}
			reduxMiddlewares={[]}
			onStoreCreated={(store) => {
				//persistEverything({ except: ['simulation'] })(store)
				persistSimulation(store)
			}}
			initialStore={{
				//...retrievePersistedState(),
				previousSimulation: persistedSimulation,
				iframeOptions: { iframeShareData },
				actionChoices: persistedSimulation?.actionChoices || {},
				tutorials: persistedSimulation?.tutorials || {},
				storedTrajets: persistedSimulation?.storedTrajets || {},
			}}
			rulesURL={process.env.MODEL_URL}
			dataBranch={branch || pullRequestNumber}
		>
			<Main />
		</Provider>
	)
}
const Main = ({}) => {
	const location = useLocation()
	const isHomePage = location.pathname === '/',
		isTuto = location.pathname.indexOf('/tutoriel') === 0

	return (
		<div
			className="ui__ container"
			css={`
				@media (min-width: 800px) {
					display: flex;
					min-height: 100vh;
				}

				@media (min-width: 1200px) {
					${!isHomePage &&
					`
						transform: translateX(-4vw);
						`}
				}
				${!isHomePage && !isTuto && sessionBarMargin}
			`}
		>
			<Navigation isHomePage={isHomePage} />
			<main
				tabIndex="0"
				id="mainContent"
				css={`
					outline: none !important;
					@media (min-width: 800px) {
						flex-grow: 1;
					}
				`}
			>
				{isHomePage && (
					<nav
						css={`
							width: 100%;
							height: 100px;
						`}
					>
						<HeaderFFF />	
					</nav>
				)}
				<Routes />
			</main>
		</div>
	)
}

export const HeaderFFF = () => {
	return (
		<div css={`
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--purple);
			img {
				height: 50%;
			}
		`}>
			<LogoFFF />
		</div>		
	)
}

const Routes = ({}) => {
	return (
		<Switch>
			<Route exact path="/" component={Landing} />
			{/* Removes trailing slashes */}
			<Route
				path={'/:url*(/+)'}
				exact
				strict
				render={({ location }) => (
					<Redirect to={location.pathname.replace(/\/+$/, location.search)} />
				)}
			/>
			<Route path="/documentation" component={Documentation} />
			<Route path="/simulateur/:name+" component={Simulateur} />
			<Route path="/stats">
				<Suspense fallback="Chargement">
					<StatsLazy />
				</Suspense>
			</Route>
			{/* Lien de compatibilité, à retirer par exemple mi-juillet 2020*/}
			<Route path="/fin/:score" component={Fin} />
			<Route path="/fin" component={Fin} />
			<Route path="/personas" component={Personas} />
			<Route path="/actions" component={Actions} />
			<Route path="/contribuer/:input?" component={Contribution} />
			<Route path="/à-propos" component={About} />
			<Route path="/cgu">
				<Suspense fallback="Chargement">
					<CGULazy />
				</Suspense>
			</Route>
			<Route path="/partenaires" component={Diffuser} />
			<Route path="/diffuser" component={Diffuser} />
			<Route path="/vie-privée">
				<Suspense fallback="Chargement">
					<PrivacyLazy />
				</Suspense>
			</Route>
			<Route path="/nouveautés" component={News} />
			<Route path="/profil" component={Profil} />
			{/* Here we define this specific route for the context documentation before generic groupe routes */}
			<Route path="/groupe/documentation-contexte">
				<Suspense fallback="Chargement">
					<DocumentationContexteLazy />
				</Suspense>
			</Route>
			<Route path="/groupe/:encodedName+">
				<Suspense fallback="Chargement">
					<GuideGroupeLazy />
				</Suspense>
			</Route>
			<Route path="/conférence/:room?">
				<Suspense fallback="Chargement">
					<ConferenceLazy />
				</Suspense>
			</Route>
			<Route path="/groupe/:room?">
				<GroupSwitch />
			</Route>
			<Route path="/sondage/:room?">
				<Suspense fallback="Chargement">
					<SurveyLazy />
				</Suspense>
			</Route>
			<Redirect from="/conference/:room" to="/conférence/:room" />
			<Route path="/tutoriel" component={Tutorial} />
			<Route component={Route404} />
		</Switch>
	)
}
