import { useLocation } from 'react-router'
import { useSearchParams } from '../../components/utils/useSearchParams'
export default ({ categories, metric, selected, countByCategory }) => {
	const location = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()

	return (
		<ul
			css={`
				display: flex;
				flex-wrap: wrap;
				list-style-type: none;
				justify-content: center;
				padding-left: 0;
				@media (max-width: 800px) {
					flex-wrap: nowrap;
					overflow-x: auto;
					white-space: nowrap;
					justify-content: normal;
					height: 3rem;
					scrollbar-width: none;
				}
				li {
					padding: 6px 12px;
					margin: 0.15rem 0.2rem;
					border-radius: 80px;
					line-height: 42px;
					height: 42px;
					display: flex;
					align-items: center;
				}
				li button {
					color: white;
					font-size: 16px;
					font-weight: 500;
				}
				button span {
					font-size: 14px;
				}
			`}
		>
			{categories.map((category) => (
				<li
					key={category.dottedName}
					css={`
						background: ${category.color};
						${selected && 'background: #aaa;'}
						${selected === category.dottedName
							? `background: ${category.color}`
							: ''}
						${!countByCategory[category.dottedName] ? 'background: #ccc' : ''}
					`}
				>
					<button
						tabindex="-1"
						css="text-transform: capitalize"
						onClick={() =>
							setSearchParams(
								new URLSearchParams({
									...(metric ? { métrique: metric } : {}),
									...(selected === category.dottedName
										? {}
										: { catégorie: category.dottedName }),
								})
							)
						}
					>
						{category.dottedName}{' '}
						<span
							css={`
								background: white;
								color: var(--purple);
								border-radius: 24px;
								width: 24px;
								height: 24px;
								line-height: 24px;
								display: flex;
								align-items: center;
								margin-left: 0.2rem;
								display: inline-block;
							`}
						>
							{countByCategory[category.dottedName] || 0}
						</span>
					</button>
				</li>
			))}
		</ul>
	)
}
