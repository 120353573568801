import emoji from 'react-easy-emoji'
import { useDispatch } from 'react-redux'
import { actionImg } from '../../components/SessionBar'
import { skipTutorial } from '../../actions/actions'
import { FFFButton } from './fin/Buttons'

export default ({ value, unit }) => {
	const dispatch = useDispatch()
	return (
		<div css={`
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			background-color: var(--gold);
			h1 {
				font-size: 50px;
				margin: 60px 0px 30px;
				color: var(--purple);
				letter-spacing: 0px;
			}
			> div {
				margin: 16px 0;
			}
			* {
				font-family: 'Poppins', sans-serif;
			}
			p {
				color: white;
				font-size: 15px;
				line-height: 18px;
				margin-bottom: 8px;
				padding: 0;
			}
			button {
				margin-top: 32px;
			}
		`}>
			<h1>
				PASSER À L’ACTION
			</h1>
			<div>
				<p>Vous avez terminé votre simulation, bravo !</p>
				<p>
					Vous connaissez maintenant votre empreinte, estimée à {value} {unit}, <br/>
					et vous avez sûrement déjà des idées pour la réduire.
				</p>
				<p>
					Pour vous aider, nous vous présenterons{' '}
					<strong>une liste d'actions</strong> :
				</p>
			</div>
			<div>
				<p>{emoji('✅')} sélectionnez celles qui vous intéressent</p>
				<p>
					{emoji('❌')} écartez celles qui vous semblent trop ambitieuses ou
					déplacées.
				</p>
				<p>
					<b>?</b> Pour améliorer la précision, certaines actions vous
					poseront quelques questions en plus.
				</p>
			</div>
			<FFFButton 
				colorType="fff_blue"
				onClick={() => dispatch(skipTutorial('actions'))}
				text="Démarrer"
			/>
		</div>
	)
}
