import animate from 'Components/ui/animate'
import LogoADEME from 'Images/LogoADEME'
import { useContext } from 'react'
import emoji from 'react-easy-emoji'
import { Link } from 'react-router-dom'
import NewsBanner from '../../components/NewsBanner'
import { openmojiURL } from '../../components/SessionBar'
import Meta from '../../components/utils/Meta'
import { TrackerContext } from '../../components/utils/withTracker'
import DocumentationButton from './DocumentationButton'
import { useProfileData } from './Profil'
import { FFFButton } from './fin/Buttons'

export default () => {
	const tracker = useContext(TrackerContext)
	return (
		<div
			css={`
				width: 100%;
				height: calc(100vh - 100px);
				position: relative;
				background: transparent url('../../images/pelouse.png') center no-repeat padding-box;
				background-size: auto 100%;
				background-color: black;
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				@media (miN-width: 985px) {
					background-size: 105% auto;
				}
				> div:first-child {
					position: absolute;
					left: 50%;
					top: 25%;
					transform: translate(-50%,-50%);
					display: flex;
					flex-direction: column;
					align-items: center;
					h1 {
						font-family: 'Poppins', sans-serif;
						color: white;
						font-weight: bold;
						text-align: center;
						margin: 0;
					}
					h1:first-child {
						font-size: 40px;
						line-height: 40px;
						@media (max-width: 800px) {
							font-size: 30px;
							line-height: 30px;
						}
					}
					h1:last-child {
						font-size: 60px;
						line-height: 60px;
						@media (max-width: 800px) {
							font-size: 40px;
							line-height: 40px;
						}
					}
					.several {
						font-family: 'Poppins', sans-serif;
						color: white;
						font-weight: bold;
						font-size: 10px;
						line-height: 10px;
						letter-spacing: 2px;
						text-transform: uppercase;
						margin-top: 12px;
					}
				}
				#cta_button_wrapper {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
				}
				footer {
					position: absolute;
					bottom: 0;
					width: 100%;
					img {
						position: absolute;
						width: 56px;
						bottom: 0;
						right: 0;
						transform: translate(-10px,-10px);
						@media (max-width: 800px) {
							width: 40px;
						}
					}
				}
			`}
		>
			<Meta
				title="Mon Foot Carbone"
				description='La FFF s’associe à Game Earth, pour permettre aux pratiquants amateurs de football de mesurer leur empreinte carbone individuelle et de s’informer sur les gestes à adopter pour réduire leur impact écologique grâce à l’outil « Mon Foot Carbone »'
			/>
			<div>
				<h1>MON FOOT</h1>
				<h1>CARBONE</h1>
			</div>
			<div id='cta_button_wrapper'>
				<Link 
					to="/simulateur/bilan"
					className={`ui__ button fffcta fff_white`}
					onClick={() =>
						tracker.push(['trackEvent', 'NGC', 'Clic CTA accueil'])
					}
				>
					Faire le test
				</Link>
				{/* <div>
					<Link to="/groupe" className="several">
						Faire le test à plusieurs
					</Link>
				</div> */}
			</div>
			<footer>
				<img src="../../images/logo_GE.png" alt="logo Game Earth" />
				<div
					css={`
						display: flex;
						justify-content: center;
						align-items: center;
						gap: 24px;
						a {
							font-family: 'Poppins', sans-serif;
							font-size: 14px;
							font-weight: 300;
							color: white;
							letter-spacing: 2px;
							@media (max-width: 800px) {
								font-size: 12px;
							}
						}
					`}
				>
					<Link to="/à-propos">À propos</Link>
					{/* <DocumentationButton />
					<Link to="/diffuser">Diffuser</Link> */}
					{/* <ProfileLink /> */}
				</div>
				<p css={`
						margin: 9px 0 16px;
						width: 100%;
						padding: 0 60px;
						text-align: center;
						font-family: 'Poppins', sans-serif;
						font-size: 10px;
						font-weight: 300;
						line-height: 12px;
						color: white;
						letter-spacing: 1.5px;
						@media (max-width: 800px) {
							font-size: 8px;
						}
						}
					`}>Cette déclinaison, développée pour la FFF, s’appuie librement sur la version officielle de Nos Gestes Climat développé par l’ABC et Datagir (ADEME).</p>
			</footer>
		</div>
	)
}

const ProfileLink = () => {
	const { hasData } = useProfileData()
	if (!hasData) return null
	return (
		<animate.fromTop delay="1">
			<div
				css={`
					button {
						padding: 0 0.2rem !important;
						border-radius: 1rem !important;
					}
				`}
			>
				<Link to="/profil" title="Page profil">
					<button className="ui__ button plain small" type="button">
						<img
							aria-hidden="true"
							src={openmojiURL('profile')}
							css="width: 2rem; filter: invert(1)"
						/>
					</button>
				</Link>
			</div>
		</animate.fromTop>
	)
}
