import NumberedMosaic from './select/NumberedMosaic'
import SelectDevices from './select/SelectDevices'
import { DottedName } from 'Rules'

const mosaicQuestions: Array<{
	question: string
	description: string
	isApplicable: Function
	component: React.FunctionComponent
	dottedName: DottedName
}> = [
	{
		dottedName: 'divers . textile',
		question: 'Quels vêtements achetez-vous neufs en général dans une année ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('divers . textile') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'equipements . matériel',
		question: 'Sur une saison, combien d’équipements suivants achetez-vous pour la pratique du football ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('equipements . matériel') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'pratique',
		question: 'Quelle est votre pratique du football ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique') &&
			dottedName.includes(' . présent'),
		component: SelectDevices,
	},
	{
		dottedName: 'pratique . loisir',
		question: 'En moyenne, combien de séances de football (loisir uniquement) faites-vous par mois dans les contextes suivants ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique . loisir') &&
			dottedName.includes(' . nombre pratiques'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'pratique . loisir . déplacement',
		question: 'Sur un mois, combien de fois utilisez-vous les moyens de transport suivants pour aller pratiquer le football loisir ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique . loisir . déplacement') &&
			dottedName.includes(' . nombre déplacements'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'pratique . futsal . déplacement domicile',
		question: 'Sur un mois, combien de fois utilisez-vous les moyens de transport suivants pour vous rendre à l’entraînement et à vos matchs de futsal à domicile ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique . futsal . déplacement domicile') &&
			dottedName.includes(' . nombre déplacements'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'pratique . futsal . déplacement exterieur',
		question: 'Sur un mois en saison de compétition, combien de fois utilisez-vous les moyens de transport suivants pour vous rendre à vos matchs de futsal à l’extérieur ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique . futsal . déplacement exterieur') &&
			dottedName.includes(' . nombre déplacements'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'pratique . club . déplacement domicile',
		question: 'Sur un mois en saison de compétition, combien de fois utilisez-vous les moyens de transport suivants pour vous rendre à l’entraînement et à vos matchs à domicile ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique . club . déplacement domicile') &&
			dottedName.includes(' . nombre déplacements'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'pratique . club . déplacement exterieur national',
		question: 'Sur un mois en saison de compétition, combien d’heures passez-vous par moyen de transport pour vous rendre sur des matchs à l’extérieur l’échelle nationale ?  ',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique . club . déplacement exterieur national') &&
			dottedName.includes(' . nombre heures'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'pratique . club . déplacement exterieur hors national',
		question: 'Sur un mois saison de compétition, combien de fois utilisez-vous les moyens de transport suivants pour vous rendre à vos matchs à l’extérieur ? ',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique . club . déplacement exterieur hors national') &&
			dottedName.includes(' . nombre déplacements'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'pratique . international . déplacements',
		question: 'Sur une année, combien d\'heures passez-vous par moyen de transport pour vous rendre en tant que joueur sur des lieux de compétition internationale ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique . international . déplacements') &&
			dottedName.includes(' . nombre heures'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'pratique . alimentation . séance club',
		question: 'Sur une séance club (entraînement, match), quelle est votre consommation alimentaire moyenne (avant, pendant, et après) ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique . alimentation . séance club') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'pratique . alimentation . séance loisir',
		question: 'Sur une séance loisir, quelle est votre consommation alimentaire moyenne (avant, pendant, et après) ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('pratique . alimentation . séance loisir') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'spectateur . déplacements',
		question: 'A quelles échelles vous déplacez-vous pour assister à des matchs de football ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('spectateur . déplacements') &&
			dottedName.includes(' . présent'),
		component: SelectDevices,
	},
	{
		dottedName: 'spectateur . déplacements . locaux',
		question: 'Sur un mois, combien de fois utilisez-vous les moyens de transport suivants pour aller assister à un match à l’échelle locale ou régionale ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('spectateur . déplacements . locaux') &&
			dottedName.includes(' . nombre déplacements'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'spectateur . déplacements . nationaux',
		question: 'Sur une année, combien d’heures passez-vous par moyen de transport pour vous rendre comme spectateur sur des événements sportifs d’envergure nationale ou internationale ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('spectateur . déplacements . nationaux') &&
			dottedName.includes(' . nombre heures'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'spectateur . consommation . boissons',
		question: 'En général, quelle quantité de boisson consommez-vous devant et en marge de ces matchs ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('spectateur . consommation . boissons') &&
			dottedName.includes(' . volume'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'spectateur . consommation . equipements',
		question: 'Pour aller assister à ces matchs, combien d’équipements achetez-vous par an (hors équipement utilisés pour la pratique du football) ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('spectateur . consommation . equipements') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'spectateur . téléspectateur . lieu . support',
		question: 'En moyenne, combien de matchs regardez-vous par mois sur les supports suivants ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('spectateur . téléspectateur . lieu . support') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
	}
	,
	{
		dottedName: 'spectateur . téléspectateur . lieu . contexte',
		question: ' En moyenne, combien de matchs regardez-vous par mois dans les contextes suivants ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('spectateur . téléspectateur . lieu . contexte') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'spectateur . téléspectateur . boissons',
		question: 'En moyenne, un soir/après-midi de match, combien consommez-vous de :',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('spectateur . téléspectateur . boissons') &&
			dottedName.includes(' . volume'),
		component: NumberedMosaic,
	},
]

export default mosaicQuestions
