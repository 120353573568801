import { motion } from 'framer-motion'
import { utils } from 'publicodes'
import emoji from 'react-easy-emoji'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setActionChoice } from '../../actions/actions'
import { correctValue } from '../../components/publicodesUtils'
import Stamp from '../../components/Stamp'
import { useEngine } from '../../components/utils/EngineContext'
import {
	getNextQuestions,
	useNextQuestions,
} from '../../components/utils/useNextQuestion'
import {
	answeredQuestionsSelector,
	situationSelector,
} from '../../selectors/simulationSelectors'
import { humanWeight } from './HumanWeight'
import { TrackerContext } from '../../components/utils/withTracker'
import { questionConfig } from './questionConfig'

const { encodeRuleName, decodeRuleName } = utils;

export const disabledAction = (flatRule, nodeValue) =>
	flatRule.formule == null ? false : nodeValue === 0 || nodeValue === false

export const supersededAction = (dottedName, rules, actionChoices) => {
	return (
		Object.entries(rules).find(([key, r]) => {
			const supersedes = r?.action?.dépasse
			return supersedes && supersedes.includes(dottedName) && actionChoices[key]
		}) != null
	)
}

const disabledStyle = `
	filter: blur(1px);
	img {
		filter: grayscale(1);
	}
	color: var(--grayColor);
	h2 {
		color: var(--grayColor);
	}
	opacity: 0.8;
`

export const ActionListCard = ({
	evaluation,
	total,
	rule,
	focusAction,
	focused,
}) => {
	const tracker = useContext(TrackerContext)

	const dispatch = useDispatch()
	const rules = useSelector((state) => state.rules),
		{ nodeValue, dottedName, title, unit } = evaluation,
		{ icônes: icons } = rule
	const actionChoices = useSelector((state) => state.actionChoices),
		situation = useSelector(situationSelector),
		answeredQuestions = useSelector(answeredQuestionsSelector)

	const engine = useEngine()

	const flatRule = rules[dottedName],
		noFormula = flatRule.formule == null,
		disabled = disabledAction(flatRule, nodeValue)

	const remainingQuestions = getNextQuestions(
			[evaluation.missingVariables],
			questionConfig,
			answeredQuestions,
			situation,
			engine
		),
		hasRemainingQuestions = remainingQuestions.length > 0

	const filledColor = disabled ? 'grey' : 'white';

	return (
		<div css={`
			${disabled ? disabledStyle : ''}
			${focused && `border: 4px solid var(--color) !important;`}
			width: 100%;
			height: 100%;
			padding: 16px;
			border: 4px solid var(--gold);
			background-color: ${filledColor};
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			${hasRemainingQuestions && `background: #eee !important; `}
			:hover {
				border-color: var(--purple);
			}
			* {
				font-family: 'Poppins', sans-serif;
				text-align: center;
			}
		`}>
			<Link
				css={`
					width: 100%;
					flex-grow: 1;
					h2 {
						display: inline;
						font-size: 110%;
						font-weight: 500;
					}
					text-decoration: none;
				`}
				to={'/actions/' + encodeRuleName(dottedName)}
			>
				<h2>{title}</h2>
			</Link>
			<ActionValue
				{...{ dottedName, total, disabled, noFormula, engine }}
			/>
			{hasRemainingQuestions && (
				<Stamp onClick={() => focusAction(dottedName)} clickable>
					{remainingQuestions.length} question
					{remainingQuestions.length > 1 && 's'}
				</Stamp>
			)}
			<div
				css={`
					display: flex;
					justify-content: space-evenly;
					button img {
						font-size: 200%;
					}
				`}
			>
				<button
					title="Choisir l'action"
					css={`
						${hasRemainingQuestions && 'filter: grayscale(1)'}
					`}
					onClick={(e) => {
						if (hasRemainingQuestions) {
							focusAction(dottedName)
							return null
						}

						dispatch(
							setActionChoice(
								dottedName,
								actionChoices[dottedName] === true ? null : true
							)
						)
						tracker.push([
							'trackEvent',
							'/actions',
							'Vote carte action',
							'oui',
							1,
						])
						e.stopPropagation()
						e.preventDefault()
					}}
				>
					{emoji('✅')}
				</button>
				<button
					title="Rejeter l'action"
					onClick={(e) => {
						dispatch(
							setActionChoice(
								dottedName,

								actionChoices[dottedName] === false ? null : false
							)
						)
						tracker.push([
							'trackEvent',
							'/actions',
							'Vote carte action',
							'non',
							-1,
						])
						e.stopPropagation()
						e.preventDefault()
					}}
				>
					{emoji('❌')}
				</button>
			</div>
		</div>
	)
}

export const ActionGameCard = ({ evaluation, total, rule, effort }) => {
	const rules = useSelector((state) => state.rules),
		{ nodeValue, dottedName, title, unit } = evaluation,
		{ icônes: icons } = rule

	const flatRule = rules[dottedName],
		noFormula = flatRule.formule == null,
		disabled = disabledAction(flatRule, nodeValue)

	return (
		<Link
			css={`
				${disabled ? disabledStyle : ''}
				text-decoration: none;
				width: 100%;
			`}
			to={'/actions/' + encodeRuleName(dottedName)}
		>
			<div css={``}>
				<h2>{title}</h2>
				<div css={``}>
					{icons && (
						<div
							css={`
								font-size: 250%;
							`}
						>
							{emoji(icons)}
						</div>
					)}
					<ActionValue {...{ dottedName, total, disabled, noFormula }} />
				</div>
			</div>
		</Link>
	)
}
const ActionValue = ({ total, disabled, noFormula, dottedName, engine }) => {
	const situation = useSelector(situationSelector),
		evaluation = engine.evaluate(dottedName),
		rawValue = evaluation.nodeValue
	const correctedValue = correctValue({
		nodeValue: rawValue,
		unit: evaluation.unit,
	})
	const [stringValue, unit] = humanWeight(correctedValue, false, true),
		relativeValue = Math.round(100 * (correctedValue / total))

	const sign = correctedValue > 0 ? '-' : '+'

	return (
		<div
			css={`
				margin: 32px 0;
				font-size: 100%;
				strong {
					background: var(--purple);
					border-radius: 1rem;
					color: white;
					padding: 0.2rem 0.8rem;
					font-weight: bold;
					${correctedValue < 0 && `background: #e33e3e`}
				}
			`}
		>
			{noFormula ? (
				'Non chiffré'
			) : disabled ? (
				'Non applicable'
			) : (
				<div css={`
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 16px;
					span {
						font-size: 12px;
						line-height: 12px;
						margin-top: 6px;
					}
				`}>
					<strong>
						{sign} {stringValue} {unit}
					</strong>{' '}
					{total && (
						<span>
							{sign}&nbsp;{Math.abs(relativeValue)}%
						</span>
					)}
				</div>
			)}
		</div>
	)
}
